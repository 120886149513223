import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { TideItemForWebsite } from "../types";
import { Breadcrumb } from "./breadcrumb";
import { isEmpty } from "lodash";
import { ComponentContext, ComponentContextType } from "../contexts/component-provider";

interface SEOProps {
  description?: string;
  title?: string;
  keywords?: string;
  website?: TideItemForWebsite;
  breadcrumbs?: Breadcrumb[];
}

const Seo: React.FC<SEOProps> = ({ description, title, keywords, website, breadcrumbs }) => {
  const { site }: any = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            siteUrl
          }
        }
      }
    `
  );

  const { kiyohRating } = useContext<ComponentContextType>(ComponentContext);
  const metaDescription: string = description || site.siteMetadata.description;
  const defaultTitle: string = title || site.siteMetadata?.title;

  const getBreadcrumbs = () => {
    if (!breadcrumbs || isEmpty(breadcrumbs)) {
      return "";
    }
    return breadcrumbs.map((breadcrumb, index) => {
      return `
        {
          "@type": "ListItem",
          "position": ${index + 1},
          "item": {
              "@id": "${process.env.URL + breadcrumb.paths["nl-BE"]}",
              "name": "${breadcrumb.title}"
          }
        }`;
    });
  };

  return (
    <Helmet
      htmlAttributes={{
        lang: "nl",
      }}
      title={defaultTitle ?? ""}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: ``,
          // content: site.siteMetadata?.author || ``,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat()}
    >
      <script type="application/ld+json">
        {`
        {
          "@context" : "http://schema.org",
          "@type" : "Organization",
          "name" : "Voetbalreizen.com",
          "logo" : "${website?.content?.general?.logo?.url}",
          "url" : "${process.env.URL}",
          "contactPoint": {
            "@type": "ContactPoint",
            "contactType": "sales",
            "telephone": "[${website?.content?.general?.linkTelephone}]",
            "email": "info@voetbalreizen.com"
          },
          "address": {
            "@type": "PostalAddress",
            "streetAddress": "Willemsplein 34-3",
            "addressLocality": "Arnhem",
            "postalCode": "6811 KC",
            "addressCountry": "Nederland"
          },
          "sameAs" : [ "https://www.facebook.com/voetbalreizen","https://www.linkedin.com/company/reisbureau-voetbalreizen-com?trk=nav_account_sub_nav_company_admin","https://twitter.com/voetbalreis","https://www.youtube.com/channel/UCSLYiBn2X78JaPuAoJPytQw","https://www.instagram.com/voetbalreizen/","242333579120366","1921625731" ],
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "${kiyohRating?.content?.general?.rating}",
            "reviewCount": "${kiyohRating?.content?.general?.numberOfRatings}",
            "bestRating": "10",
            "worstRating": "1"
          }
        }
      `}
      </script>
      <script type="application/ld+json">
        {`
        {
            "@context": "http://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [${getBreadcrumbs()}]
        }
    `}
      </script>
    </Helmet>
  );
};

export default Seo;
