import React from "react";
import { Image } from "../../types";
import { addWidthParameter } from "../../utils/image-utils";
import Icon from "../icon";
import Link from "../link";

interface BrandProps {
  name?: string;
  logo?: Image;
  url?: string;
}

const Brand: React.FC<BrandProps> = ({ name, logo, url }) => {
  if (!name) {
    return null;
  }
  return (
    <Link path="/" url={url} title="Sportreizen" className="logo" targetBlank={false}>
      {logo?.url && <img src={addWidthParameter(logo.url, 250)} alt={logo.altText ?? name} title={logo.title ?? name} />}
      {!logo?.url && <Icon name="logo" />}
    </Link>
  );
};

export default Brand;
