import React, { Dispatch, SetStateAction, useContext } from "react";
import { TideItemForNavigationItem } from "../../../types";
import { getChildItemsWithTypename, parse } from "../../../utils/component";
import NavbarPrimaryItem from "./navbar-primary-item";
import Link from "../../link";
import Icon from "../../icon";
import GlobalContext, { GlobalContextType } from "../../../contexts/global-provider";
import { logoutMember } from "../../../services/login-services";
import { ComponentContext, ComponentContextType } from "../../../contexts/component-provider";

interface NavbarPrimaryProps {
  isDropDownOpen: boolean;
  setIsDropDownOpen: Dispatch<SetStateAction<boolean>>;
  activeDropDown?: string;
  setActiveDropDown: Dispatch<SetStateAction<string>>;
}

const NavbarPrimary: React.FC<NavbarPrimaryProps> = ({ activeDropDown, setActiveDropDown, isDropDownOpen, setIsDropDownOpen }) => {
  const { member, setMember } = useContext<GlobalContextType>(GlobalContext);
  const { primaryNavigation } = useContext<ComponentContextType>(ComponentContext);
  // nog even uitzetten op de website
  const featureFlagLoginAllowed = process.env.FFLoginAllowed === "true";

  const navigationItems = getChildItemsWithTypename<TideItemForNavigationItem>("TideItemForNavigationItem", primaryNavigation);

  const getSubItems = (navigationItem: TideItemForNavigationItem) => {
    return getChildItemsWithTypename("TideItemForNavigationItem", navigationItem) as TideItemForNavigationItem[];
  };

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const response = await logoutMember();
    if (response) {
      if (typeof sessionStorage !== "undefined") {
        sessionStorage.removeItem("token");
      }
      setMember(undefined);
    }
  };

  return (
    <div className="navbar__links">
      {navigationItems && (
        <div className="navbar__links-primary">
          {navigationItems.map((navigationItem) => (
            <NavbarPrimaryItem
              key={navigationItem.id}
              title={parse(navigationItem.content?.general?.title)}
              allItemsTitle={parse(navigationItem.content?.general?.allItemsTitle)}
              path={parse(navigationItem.content?.general?.path)}
              url={parse(navigationItem.content?.general?.url)}
              subItems={getSubItems(navigationItem)}
              isDropDownOpen={isDropDownOpen}
              setIsDropDownOpen={setIsDropDownOpen}
              activeDropDown={activeDropDown}
              setActiveDropDown={setActiveDropDown}
            />
          ))}
        </div>
      )}

      <div className="navbar__shelf-actions">
        {!member && featureFlagLoginAllowed && (
          <div className="navbar__agent">
            <div className="navbar__agent-label">
              <div className="navbar__agent-icon">
                <Icon name="user"></Icon>
              </div>
              <Link path="login" title="Login" className="navbar__link">
                <span className="navbar__agent-lineclamp">Agent login</span>
              </Link>
            </div>
          </div>
        )}
        {member && featureFlagLoginAllowed && (
          <div className="navbar__agent">
            <div className="navbar__agent-label">
              <div className="navbar__agent-icon">
                <Icon name="user"></Icon>
                <div className="navbar__agent-badge">
                  <Icon name="badge-signed-in"></Icon>
                </div>
              </div>
              <span className="navbar__agent-lineclamp">{member.name}</span>
            </div>

            <a title="Logout" className="navbar__link" onClick={(event) => handleLogout(event)}>
              Logout
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default NavbarPrimary;
