import React, { useContext, useState } from "react";
import buildClassName from "../../utils/build-class-name";
import { TideItemForWebsite } from "../../types";
import Brand from "../brand";
import NavbarActions from "./navbar-actions";
import Trust from "../trust";
import NavbarPrimary from "./navbar-primary";
import { parse } from "../../utils/component";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";

interface NavbarProps {
  website?: TideItemForWebsite;
  extraClassName?: string;
  isBooking?: boolean;
}

const Navbar: React.FC<NavbarProps> = ({ website, extraClassName, isBooking }) => {
  const { isMobileMenuOpen, setMobileMenuOpen } = useContext<GlobalContextType>(GlobalContext);

  const [activeDropDown, setActiveDropDown] = useState<string>("");
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const navbarClassName = buildClassName(["navbar", isMobileMenuOpen && "navbar--active", extraClassName]);
  return (
    <nav className={navbarClassName}>
      <div className="navbar__container">
        <div className="navbar__backdrop" onClick={() => setMobileMenuOpen(false)}></div>

        <div className="navbar__bar">
          {!isBooking && (
            <button type="button" className="hamburger" title="Menu" onClick={() => setMobileMenuOpen(!isMobileMenuOpen)}>
              <span className="hamburger__icon"></span>
            </button>
          )}

          <Brand name={parse(website?.name)} logo={parse(website?.content?.general?.logo)} url={parse(website?.content?.general?.linkLogo)} />

          <div className="navbar__top">
            <Trust
              styling="navbar__trust"
              linkSgr={parse(website?.content?.general?.linkSgr)}
              linkThuiswinkelWaarborg={parse(website?.content?.general?.linkThuiswinkelWaarborg)}
            />
            <NavbarActions
              phone={parse(website?.content?.general?.phone)}
              urlPhone={parse(website?.content?.general?.linkTelephone)}
              question={parse(website?.content?.general?.helptext)}
            />
          </div>
        </div>

        {!isBooking && (
          <div className="navbar__shelf">
            <NavbarPrimary
              isDropDownOpen={isDropDownOpen}
              setIsDropDownOpen={setIsDropDownOpen}
              activeDropDown={activeDropDown}
              setActiveDropDown={setActiveDropDown}
            />
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
