import React, { useContext } from "react";
import { Helmet } from "react-helmet";
import "react-image-lightbox/style.css";
import GlobalContext, { GlobalContextType } from "../contexts/global-provider";
import useBrokenImageTracker from "../utils/hooks/use-broken-image-tracker";

const Layout: React.FC = ({ children }) => {
  if (process.env.WEBSITE === "Sportreizen") {
    require("./../scss/main.sportreizen.scss");
  } else {
    require("./../scss/main.voetbalreizen.scss");
  }

  useBrokenImageTracker();
  
  const { qsmActive, filtersActive, isMobileMenuOpen, isBookingPage } = useContext<GlobalContextType>(GlobalContext);

  return (
    <div className={`page-components${qsmActive ? " qsm-active" : ""}${isBookingPage ? " page-components--booking" : ""}`}>
      <Helmet>
        <body className={`${qsmActive ? "ios-keyboard-open" : ""} ${filtersActive || isMobileMenuOpen ? "has-overlay" : ""}`} />
      </Helmet>
      {children}
    </div>
  );
};

export default Layout;
