import React from "react";
import Icon from "../icon";

interface StarsProps {
  stars?: number;
  inList: boolean;
}

const Stars: React.FC<StarsProps> = ({ stars, inList }) => {
  if (!stars) {
    return null;
  }

  // some dirty magic to show the stars
  const fullStarsArray = [];
  if (stars) {
    for (let i = 0; i < Math.trunc(stars); i++) {
      fullStarsArray.push({});
    }
  }
  const emptyStarsArray = [];
  for (let i = 0; i < 5 - fullStarsArray.length - (stars % 1 > 0 ? 1 : 0); i++) {
    emptyStarsArray.push({});
  }

  return (
    <>
      {inList && (
        <>
          {fullStarsArray.map((s, i) => (
            <li key={i}>
              <Icon name="star-full" />
            </li>
          ))}
          {stars % 1 > 0 && (
            <li>
              <Icon name="star-half" />
            </li>
          )}
          {emptyStarsArray.map((s, i) => (
            <li key={i + 5}>
              <Icon name="star-empty" />
            </li>
          ))}
        </>
      )}

      {!inList && (
        <>
          {fullStarsArray.map((s, i) => (
            <Icon name="star-full" key={i} />
          ))}
          {stars % 1 > 0 && <Icon name="star-half" />}
          {emptyStarsArray.map((s, i) => (
            <Icon name="star-empty" key={i + 5} />
          ))}
        </>
      )}
    </>
  );
};

export default Stars;
