import { useEffect } from 'react';
import * as Sentry from '@sentry/gatsby';

const useBrokenImageTracker = () => {
    useEffect(() => {
        const handleError = (event: any) => {
            if (event.target.tagName === 'IMG') {
                const imageUrl = event.target.src;
                Sentry.captureMessage(`Broken image: ${imageUrl}`, {
                    level: 'error',
                    extra: {
                        imageUrl,
                    },
                });
            }
        };

        window.addEventListener('error', handleError, true);

        return () => {
            window.removeEventListener('error', handleError, true);
        };
    }, []);
};

export default useBrokenImageTracker;