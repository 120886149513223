import React from "react";
import { TideItemForNavigationItem } from "../../../types";
import { parse } from "../../../utils/component";
import { generateRootPath } from "../../../utils/path";
import Link from "../../link";

interface NavbarPrimarySubItemProps {
  title?: string;
  path?: string;
  url?: string;
  subItemChildren?: TideItemForNavigationItem[];
}

const NavbarPrimarySubItem: React.FC<NavbarPrimarySubItemProps> = ({ title, path, url, subItemChildren }) => {
  return (
    <Link
      title={title}
      path={path}
      url={url}
      className={`dropdown__option ${subItemChildren && subItemChildren.length > 0 ? "dropdown__option--has-children" : ""}`}
    >
      {title}
      {subItemChildren && subItemChildren.length > 0 && (
        <div className="dropdown__option-children">
          <div className="dropdown__options">
            {subItemChildren.map((item) => (
              <Link
                key={item.id}
                path={generateRootPath(parse(item.content?.general?.path))}
                url={parse(item.content?.general?.url)}
                title={parse(item.content?.general?.title)}
                className="dropdown__option"
              >
                {item.content?.general?.title}
              </Link>
            ))}
          </div>
        </div>
      )}
    </Link>
  );
};

export default NavbarPrimarySubItem;
