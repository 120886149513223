export const getPlaceHolderImage = () => {
  if (process.env.WEBSITE === "Sportreizen") {
    return "https://media.tidesoftware.be/media/2/Website/Sportreizen.com/Placeholders/placeholder-spr-1.webp?height=200";
  } else {
    return "https://media.tidesoftware.be/media/2/Website/Voetbalreizen.com/Placeholders/placeholder-vbr-1.webp?height=200";
  }
};

export const addWidthParameter = (url: string | undefined, width: number) => {
  if (!url) {
    return;
  }

  if (url.endsWith(".svg")) {
    return url;
  }

  const queryStringIndex = url.indexOf("?");
  const queryString = queryStringIndex > -1 ? url.slice(queryStringIndex) : undefined;
  const params = new URLSearchParams(queryString);

  params.set("width", width.toString());

  return `${url.slice(0, queryStringIndex > -1 ? queryStringIndex : undefined)}?${params.toString()}`;
};
