import { login, confirm, logout, forgotPassword, resetPassword } from "@qite/tide-client";
import { MemberConfirmationRequest, MemberForgotPasswordRequest, MemberLoginRequest, MemberResetPasswordRequest } from "@qite/tide-client/build/types";
import { getTideClientConfig } from "../utils/member-and-tide-utils";

const HOST = process.env.GATSBY_TIDE_HOST;
const API_KEY = process.env.GATSBY_TIDE_API_KEY;

export const loginMember = async (username: string, password: string) => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const memberLogingRequest: MemberLoginRequest = {
    username: username,
    password: password
  };

  const memberInfo = await login(tideClientConfig, memberLogingRequest, undefined);
  return memberInfo;
};

export const confirmMember = async (token: string, password: string, hasAgreed: boolean) => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const memberConfirmationRequest: MemberConfirmationRequest = {
    token: token,
    password: password,
    hasAgreed: hasAgreed
  };

  const response = await confirm(tideClientConfig, memberConfirmationRequest, undefined);
  return response;
};

export const logoutMember = async () => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const response = await logout(tideClientConfig, undefined);
  return response.ok;

};

export const passwordForgotten = async (username: string) => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const memberForgotPasswordRequest: MemberForgotPasswordRequest = {
    username: username
  };

  const response = await forgotPassword(tideClientConfig, memberForgotPasswordRequest, undefined);
  return response.ok;
};

export const newPassword = async (token: string, password: string) => {
  if (!HOST || !API_KEY) {
    return Promise.reject();
  }

  const tideClientConfig = getTideClientConfig();

  const memberResetPasswordRequest: MemberResetPasswordRequest = {
    token: token,
    password: password
  };

  const response = await resetPassword(tideClientConfig, memberResetPasswordRequest, undefined);
  return response.ok;
};
