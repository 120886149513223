import { kebabCase, trim } from "lodash";
import { TideItemForSporten, TideItemForToernooi } from "../types";

export const generatePath = (
  path: string,
  rootPath: string = ""
) => {

  let fullPath = `${[rootPath, path].map((part) => trim(part ?? undefined, "/")).join("/")}`;

  fullPath = fullPath.endsWith("/") ? fullPath : `${fullPath}/`

  return fullPath.startsWith("/") ? fullPath : `/${fullPath}`;
};

export const generateRootPath = (path: string | undefined) => {
  if (path === undefined) {
    return undefined;
  }

  return `/${path}/`;
};

export const generateTournamentPath = (tournament: TideItemForToernooi, sport: TideItemForSporten | null) => {
  if (sport) {
    return generatePath(tournament.content?.general?.path ?? kebabCase(tournament.name), sport.content?.general?.path ?? kebabCase(sport.name))
  }
  return generatePath(tournament.content?.general?.path ?? kebabCase(tournament.name))
}
