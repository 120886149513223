import { Link as GatsbyLink } from "gatsby";
import React, { useContext } from "react";
import { isNil } from "lodash";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";

interface LinkProps {
  title?: string;
  children?: React.ReactNode;
  path?: string;
  url?: string;
  className?: string;
  targetBlank?: boolean;
}

const Link: React.FC<LinkProps> = ({ children, title, path, url, className, targetBlank }) => {
  const { setMobileMenuOpen } = useContext<GlobalContextType>(GlobalContext);

  const createInternalLink = () => {
    if (isNil(path) && isNil(url)) {
      path = `/`;
    }

    if (isNil(path)) {
      return null;
    }

    const fullPath = path.startsWith("/") ? path : `/${path}`;
    if (targetBlank) {
      return (
        <a href={fullPath} title={title} className={className} target="_blank">
          {children}
        </a>
      );
    }
    return (
      <a href={fullPath} title={title} className={className} onClick={() => setMobileMenuOpen(false)}>
        {children}
      </a>
    );
  };

  const createExternalLink = () => {
    if (isNil(url)) {
      return null;
    }

    const targetAttributes = targetBlank === undefined || targetBlank ? { target: "_blank", rel: "noreferrer noopener" } : {};

    return (
      <a href={url} title={title} className={className} {...targetAttributes}>
        {children}
      </a>
    );
  };

  return !isNil(url) ? createExternalLink() : createInternalLink();
};

export default Link;
