import React from "react";
import icons from "../images/sportreizen_icons.svg";
import { isEmpty } from "lodash";

interface IconProps {
  name: string;
  className?: string;
}

const Icon: React.FC<IconProps> = ({ name, className }) => {
  return (
    <svg className={isEmpty(className) ? "icon" : className}>
      <use href={`${icons}#${name}`}></use>
    </svg>
  );
};

export default Icon;
