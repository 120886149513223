import React, { useContext } from "react";
import { parse } from "../../utils/component";
import translate from "../../utils/translate";
import Icon from "../icon";
import Link from "../link";
import Stars from "../stars";
import translations from "./translations.json";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import { ComponentContext, ComponentContextType } from "../../contexts/component-provider";

interface TrustProps {
  styling: string;
  linkSgr?: string;
  linkThuiswinkelWaarborg?: string;
  showStars?: boolean;
}

const Trust: React.FC<TrustProps> = ({ styling, linkSgr, linkThuiswinkelWaarborg, showStars = true }) => {
  const { kiyohRating } = useContext<ComponentContextType>(ComponentContext);
  const { language } = useContext<GlobalContextType>(GlobalContext);

  if (!kiyohRating?.content?.general?.rating) {
    return null;
  }

  const ratingContent = kiyohRating.content.general;

  // some dirty magic to show the stars
  const stars = [];
  if (ratingContent.stars) {
    for (let i = 0; i < Math.trunc(ratingContent.stars); i++) {
      stars.push({});
    }
  }

  return (
    <div className={styling}>
      <div className="list-inline">
        <ul>
          <li>
            <Link url={parse(linkSgr)} title={translate(translations, language, "GO_TO_SGR_TITLE")}>
              <Icon name="sgr" />
            </Link>
          </li>
          <li>
            <Link url={parse(linkThuiswinkelWaarborg)} title={translate(translations, language, "GO_TO_TWW_TITLE")}>
              <Icon name="thuiswinkel-waarborg" />
            </Link>
          </li>
          <li>
            <Icon name="kiyoh" />
            {showStars && (
              <div className="rating">
                <div className="list-inline">
                  <ul>
                    <Stars stars={parse(ratingContent.stars)} inList={true} />
                  </ul>
                </div>
              </div>
            )}
          </li>
          <li>
            {`${ratingContent.rating} ${translate(translations, language, "FROM")} `}&nbsp;
            <Link url={parse(ratingContent.allReviewsUrl)} title={translate(translations, language, "GO_TO_ALL_REVIEWS_TITLE")}>
              {` ${ratingContent.numberOfRatings} ${translate(translations, language, "EXPERIENCES")}`}
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Trust;
