import { isEmpty, isNil } from "lodash";
import React, { Dispatch, SetStateAction } from "react";
import { TideItemForNavigationItem } from "../../../types";
import { getChildItemsWithTypename, parse } from "../../../utils/component";
import { generateRootPath } from "../../../utils/path";
import Link from "../../link";
import NavbarPrimarySubItem from "./navbar-primary-sub-item";
import Icon from "../../icon";

interface NavbarPrimaryItemProps {
  title?: string;
  allItemsTitle?: string;
  path?: string;
  url?: string;
  subItems?: TideItemForNavigationItem[];
  isDropDownOpen: boolean;
  setIsDropDownOpen: Dispatch<SetStateAction<boolean>>;
  activeDropDown?: string;
  setActiveDropDown: Dispatch<SetStateAction<string>>;
}

const NavbarPrimaryItem: React.FC<NavbarPrimaryItemProps> = ({
  title,
  allItemsTitle,
  path,
  url,
  subItems,
  activeDropDown,
  setActiveDropDown,
  isDropDownOpen,
  setIsDropDownOpen,
}) => {
  const getSubItemsChildren = (navigationItem: TideItemForNavigationItem) => {
    return getChildItemsWithTypename("TideItemForNavigationItem", navigationItem) as TideItemForNavigationItem[];
  };

  const handleDropDown = () => {
    if (title == activeDropDown && isDropDownOpen) {
      setIsDropDownOpen(false);
    }
    if (title == activeDropDown && !isDropDownOpen) {
      setIsDropDownOpen(true);
    }
    if (title && title != activeDropDown) {
      setActiveDropDown(title);
      setIsDropDownOpen(true);
    }
  };

  return (
    <>
      {isEmpty(subItems) && (
        <Link title={title} path={generateRootPath(path)} url={url} className="navbar__link">
          {title}
        </Link>
      )}
      {!isEmpty(subItems) && !isNil(subItems) && (
        <>
          <div className="dropdown__mobile">
            <div className={`dropdown ${isDropDownOpen && title == activeDropDown ? "dropdown--active" : ""}`}>
              <div className="dropdown__trigger" onClick={() => handleDropDown()}>
                <span className="dropdown__option">{title}</span>
                <Icon name="arrow-right" />
              </div>
              <div className="dropdown__options">
                <button type="button" className="dropdown__collapse-button" onClick={() => handleDropDown()}><Icon name="arrow-left" />Terug</button>
                <NavbarPrimarySubItem
                  title={allItemsTitle}
                  path={generateRootPath(path)} url={url}
                />
                {subItems.map((subItem) => (
                  <NavbarPrimarySubItem
                    key={subItem.id}
                    title={parse(subItem.content?.general?.title)}
                    path={generateRootPath(parse(subItem.content?.general?.path))}
                    url={parse(subItem.content?.general?.url)}
                    subItemChildren={getSubItemsChildren(subItem)}
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="dropdown__desktop">
            <div className={`dropdown ${isDropDownOpen && title == activeDropDown ? "dropdown--active" : ""}`}>
              <div className="dropdown__trigger">
                <Link title={title} path={generateRootPath(path)} url={url} className="dropdown__option">
                  {title}
                </Link>
                <button type="button" className="dropdown__collapse-button" onClick={() => handleDropDown()}></button>
              </div>
              <div className="dropdown__options">
                {subItems.map((subItem) => (
                  <NavbarPrimarySubItem
                    key={subItem.id}
                    title={parse(subItem.content?.general?.title)}
                    path={generateRootPath(parse(subItem.content?.general?.path))}
                    url={parse(subItem.content?.general?.url)}
                    subItemChildren={getSubItemsChildren(subItem)}
                  />
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default NavbarPrimaryItem;
