import React, { useContext } from "react";
import GlobalContext, { GlobalContextType } from "../../contexts/global-provider";
import { logoutMember } from "../../services/login-services";
import Icon from "../icon";
import Link from "../link";

interface NavbarActionsProps {
  question?: string;
  phone?: string;
  urlPhone?: string;
}

const NavbarActions: React.FC<NavbarActionsProps> = ({ question, phone, urlPhone }) => {
  const { member, setMember } = useContext<GlobalContextType>(GlobalContext);
  // nog even uitzetten op de website
  const featureFlagLoginAllowed = process.env.FFLoginAllowed === "true";

  const handleLogout = async (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
    e.preventDefault();
    const response = await logoutMember();
    if (response) {
      if (typeof sessionStorage !== "undefined") {
        sessionStorage.removeItem("token");
      }
      setMember(undefined);
    }
  };

  return (
    <div className="navbar__actions">
      {!member && featureFlagLoginAllowed && (
        <div className="navbar__agent">
          <div className="navbar__agent-label">
            <div className="navbar__agent-icon">
              <Icon name="user"></Icon>
            </div>
            <Link path="login" title="Login" className="navbar__link">
              <span className="navbar__agent-lineclamp">Agent login</span>
            </Link>
          </div>
        </div>
      )}
      {member && featureFlagLoginAllowed && (
        <div className="navbar__agent">
          <div className="navbar__agent-label">
            <div className="navbar__agent-icon">
              <Icon name="user"></Icon>
              <div className="navbar__agent-badge">
                <Icon name="badge-signed-in"></Icon>
              </div>
            </div>
            <span className="navbar__agent-lineclamp">{member.name}</span>
          </div>

          <a title="Logout" className="navbar__link" onClick={(event) => handleLogout(event)}>
            Logout
          </a>
        </div>
      )}
      <div className="navbar__telephone">
        <Link url={`tel://${urlPhone}`} title={phone}>
          <Icon name="telephone" />
          {phone}
        </Link>
      </div>
    </div>
  );
};

export default NavbarActions;
