import { get } from "lodash";

export default function translate(
  object: object,
  language: string,
  key: string,
  replacements: Record<string, any> = {}
) {
  const translation = get(object, `${language}.${key}`, key) as string;

  return Object.keys(replacements).reduce(
    (str, key) => str.replace(`\${${key}}`, replacements[key]),
    translation
  );
}
